<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-body card">
        <div class="row">
          <div class="col-md-12 register-title">
            <h3 class="mb-3">Donation</h3>
            <div  class="imgwithinline" v-if="propAddons && propAddons.alfa_donation">
              <img v-if="propAddons.alfa_donation.banner && propAddons.alfa_donation.banner.element && propAddons.alfa_donation.banner.element == 'img'" :src="propAddons.alfa_donation.banner.attributes.src" :class="propAddons.alfa_donation.banner.class">
              <div class="form-text">
                <span v-html="propAddons.alfa_donation.content"></span>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="form-group">
              <label class="form-label">{{ propAddons.alfa_donation.label || 'Donation'}}</label><br>
              <template v-if="propAddons.alfa_donation.choices">
                <template v-for="(choice, key) in propAddons.alfa_donation.choices">
                  <div class="form-check-inline" :key="'alfa_donation_br_'+key">
                    <label class="form-check-label">
                      <input
                          type="radio"
                          :id="choice.value"
                          v-model="form.donation_amount"
                          name="form.alfa_donation"
                          :value="choice.value"
                          :key="'radio_' + key"
                          @change="updateDonationData"
                      >
                      {{ choice.label }}
                    </label>
                  </div>
                </template>
                <input class="custom-input" v-if="form.donation_amount === 'null'" :key="'alfa_donation_input_'+key" type="text" v-model="form.added_donation_amount" @keyup="updateDonationData">
                <span class="text-danger" v-if="form.errors && form.errors.donation && form.errors.donation.other">
                  {{ form.errors.donation.other }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus';
export default {
  props: ['formData'],
  data(){
    return {
      form: {}
    }
  },
  methods: {
    updateDonationData(){
      if(
          this.form.donation_amount === "null" &&
          (this.form.added_donation_amount === undefined || isNaN(this.form.added_donation_amount) || this.form.added_donation_amount === "")
      ){
        Object.assign(this.form, { errors: { donation: { other: 'Amount is required' } } });
      } else {
        Object.assign(this.form, { errors: { donation: {} } });
      }
      this.$emit("recordDonation", this.form);
    }
  },
  mounted() {
    EventBus.$on('removeDonation', (value) => {
      this.form.donation_amount = 0;
      delete this.form.added_donation_amount;
    });
  },
  computed: {
    propAddons() {
      return this.getSiteProps('memberRegistration.individual.fields.addons');
    }
  }
}
</script>
<style>
.imgwithinline img {
  width: 100% !important;
  float: left;
  max-width: 163px;
  height: 100px;
  margin-right: 19px;
}
</style>