<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-body card">
        <div class="row">
          <div class="col-md-12 register-title">
            <h3 class="mb-3">Membership Types</h3>
            <div v-if="memberships && memberships.length">
              <ul class="mship-type-fld">
                <template v-for="membership in memberships">
                  <li class="member-type-width mr-2" :key="'membership_key_'+membership.id" :class="{ 'disabled' : membership.blocked }">
                    <input
                        type="radio"
                        :id="'cbmembership_select_'+membership.id"
                        name="form.membership_id"
                        :value="membership.id"
                        v-model="form.membership_id"
                        @change="updateMembershipSelection"
                        :disabled="membership.blocked"
                    >
                    <label :for="'cbmembership_select_'+membership.id">
                      <span class="chk-top-text pb-3">{{ membership.name }}</span>
                      <div class="pb-3">
                        <img src="/svg/alliancefastpitch/user-single.svg" alt="icon-3">
                      </div>
                      <span class="chk-bot-text pb-2">${{ membership.price }}</span>
                      <span class="chk-box-text" v-html="membership.note"></span>
                    </label>
                  </li>
                </template>
              </ul>
            </div>
            <div v-else>
              <i class="fa fa-circle-notch fa-spin fa-2x"></i>
            </div>
          </div>
        </div>
        <div class="row" v-if="error">
          <div class="col-md-12 text-center">
            <p class="text-danger">{{ error }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { EventBus } from '@/event-bus';
export default {
  data() {
    return {
      memberships: [],
      member: null,
      form: {},
      error: ""
    }
  },
  methods: {
    fetchMemberships(){
      axios.get(this.basePath+'api/renew/memberships-list/individual', { headers: this.memberHeaders })
          .then(response => {
            this.memberships = response.data.data;
            this.member = JSON.parse(localStorage.member);
            this.form.membership_id = this.member.reg_membership_id;
            this.updateMembershipSelection();
          })
          .catch(error => {
            console.log(error);
          })
    },
    updateMembershipSelection(){
      let selectedMembership = this.memberships.filter(item => item.id === this.form.membership_id);
      if(selectedMembership.length) this.form.membership = selectedMembership.shift();

      //Detect membership changed
      this.form.membership_upgraded = this.form.membership_id !== this.member.reg_membership_id;
      this.$emit("recordMembership", this.form);
    }
  },
  mounted() {
    this.fetchMemberships();
    EventBus.$on('validateMembership', value => {
      this.error = null;
      if(value) this.error = "Please upgrade your membership to proceed further";
    });
  }
}
</script>