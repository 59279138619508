<template>
  <div class="verify-view">
    <template v-if="dataLoading === false">
      <div class="row" v-if="memberInfo.team_age_out">
        <div class="col-md-12">
          <div class="alert alert-warning">
            <i class="fa fa-exclamation-triangle fa-lg mr-2"></i> You are aged out in the current division. You must change the team for next higher age division.
          </div>
        </div>
      </div>
      <div class="row" v-if="memberInfo.membership_id === 5">
        <div class="col-md-12">
          <div class="alert alert-dark">
            <i class="fa fa-exclamation-circle fa-lg mr-2"></i> If you are renewing your team membership you must login to your team account.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body card">
            <div class="row">
              <div class="col-md-12">
                <h3>Membership</h3>
                <span class="Individual" v-if="memberInfo.membership">{{ memberInfo.membership.name }} Membership</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row user-details" :key="updateIndex">
        <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-10">
                  <div class="header-title">
                    <h3 class="mb-0" v-if="fields.personal && fields.personal.tab_title">{{  fields.personal.tab_title }}</h3>
                  </div>
                </div>
                <div class="col-md-2">
                  <div  class="modal-btn text-right">
                    <b-button v-b-modal.edit-personal><span class="fa fa-edit mb-0"></span></b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.firstname">
                  <div class="form-group">
                    <label class="form-label">{{ fields.personal.firstname.label }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.first_name === null }">{{ memberInfo.first_name || "N/A" }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.middlename">
                  <div class="form-group">
                    <label class="form-label">{{ fields.personal.middlename.label }}</label>
                    <span>{{ memberInfo.middle_name || "N/A" }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.lastname">
                  <div class="form-group">
                    <label class="form-label">{{ fields.personal.lastname.label }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.last_name === null }">{{ memberInfo.last_name || "N/A" }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.birthday">
                  <div class="form-group">
                    <label class="form-label">{{ fields.personal.birthday.label }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.dob === null }">{{ memberInfo.dob || "N/A" }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.team_membership_id">
                  <div class="form-group">
                    <label class="form-label">{{ fields.personal.team_membership_id.label }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.affiliated_clubs_names === '' || memberInfo.team_age_out }"  >{{ memberInfo.affiliated_clubs_names || "N/A" }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.team_membership_id">
                  <div class="form-group">
                    <label class="form-label">Age Division</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.age_division === '' || memberInfo.team_age_out }">{{ memberInfo.age_division || "N/A" }}</span>
                  </div>
                </div>
                <template v-if="memberInfo.membership && memberInfo.membership.id !== 5">
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.agl_player_id">
                    <div class="form-group">
                      <label class="form-label">{{ fields.personal.agl_player_id.label }}</label>
                      <span class="title">{{ memberInfo.agl_player_id || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.graduation_year">
                    <div class="form-group">
                      <label class="form-label">{{ fields.personal.graduation_year.label }}</label>
                      <span :class="{ 'text-danger text-bold': memberInfo.graduation_year === null }">{{ memberInfo.graduation_year || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.jersey_number">
                    <div class="form-group">
                      <label class="form-label">Jersey Number</label>
                      <span :class="{ 'text-danger text-bold': memberInfo.jersey_number === null }">{{ memberInfo.jersey_number || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.primary_position">
                    <div class="form-group">
                      <label class="form-label">Primary Position</label>
                      <span :class="{ 'text-danger text-bold': memberInfo.primary_position === null }">{{ memberInfo.primary_position || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.secondary_position">
                    <div class="form-group">
                      <label class="form-label">Secondary Position</label>
                      <span class="title">{{ memberInfo.secondary_position || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.throw">
                    <div class="form-group">
                      <label class="form-label">Throws</label>
                      <span class="title">{{ memberInfo.throws==1?'Left':'Right' }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.bats">
                    <div class="form-group">
                      <label class="form-label">Bats</label>
                      <span class="title">{{ memberInfo.bats==1?'Left':'Right' }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.college_committed">
                    <div class="form-group">
                      <label class="form-label">College Committed</label>
                      <span v-if="memberInfo.college_committed_text">{{ memberInfo.college_committed_text || "N/A" }}</span>
                      <span v-else>N/A</span>
                    </div>
                  </div>
                </template>
                <div class="col-md-6 col-lg-4" v-if="fields.personal && fields.personal.member_league">
                  <div class="form-group">
                    <label class="form-label">Member League</label>
                    <span class="title">{{ memberInfo.member_league || "N/A" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-10">
                  <div class="header-title">
                    <h3 class="mb-0" v-if="fields.contact && fields.contact.tab_title">{{  fields.contact.tab_title }}</h3>
                  </div>
                </div>
                <div class="col-md-2">
                  <div  class="modal-btn text-right">
                    <b-button v-b-modal.edit-contact><span class="fa fa-edit mb-0"></span></b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="row">
                  <template v-if="memberInfo.membership && memberInfo.membership.id !== 5">
                    <div  class="col-md-6 col-lg-4 " v-if="fields.contact && fields.contact.firstname">
                      <div class="form-group">
                        <label class="form-label">{{ fields.contact.firstname.label }}</label>
                        <span :class="{ 'text-danger text-bold': memberInfo.parent_first_name === null }">{{ memberInfo.parent_first_name || "N/A" }}</span>
                      </div>
                    </div>
                    <div  class="col-md-6 col-lg-4 " v-if="fields.contact && fields.contact.middlename">
                      <div class="form-group">
                        <label class="form-label">{{ fields.contact.middlename.label }}</label>
                        <span >{{ memberInfo.parent_middle_name || "N/A" }}</span>
                      </div>
                    </div>
                    <div  class="col-md-6 col-lg-4 " v-if="fields.contact && fields.contact.lastname">
                      <div class="form-group">
                        <label class="form-label">{{ fields.contact.lastname.label }}</label>
                        <span :class="{ 'text-danger text-bold': memberInfo.parent_last_name === null }">{{ memberInfo.parent_last_name || "N/A" }}</span>
                      </div>
                    </div>
                  </template>
                  <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.homephone">
                    <div class="form-group">
                      <label class="form-label">{{fields.contact.homephone.label || 'Home Phone'}}</label>
                      <span :class="{ 'text-danger text-bold': memberInfo.phone_1 === null }">{{ memberInfo.phone_1 || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.mobilephone">
                    <div class="form-group">
                      <label class="form-label">{{fields.contact.mobilephone.label || 'Mobile Phone'}}</label>
                      <span >{{ memberInfo.phone_2 || "N/A"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.email">
                    <div class="form-group">
                      <label class="form-label">{{ fields.contact.email.label || 'Email' }}</label>
                      <span :class="{ 'text-danger text-bold': memberInfo.email === null }">
                        <a class="email-link" v-bind:href="'mailto:' + memberInfo.email" v-if="memberInfo.email">{{ memberInfo.email }}</a>
                        <template v-else>N/A</template>
                      </span>
                    </div>
                  </div>
                </div>
                <hr/>
                <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.address1">
                  <div class="form-group">
                    <label class="form-label">{{ fields.contact.address1.label || 'Mailling Address' }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.address1 === null }">{{ memberInfo.address1 || "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.address2">
                  <div class="form-group">
                    <label class="form-label">{{ fields.contact.address2.label || 'Address2' }}</label>
                    <span >{{ memberInfo.address2 || "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.city">
                  <div class="form-group">
                    <label class="form-label">{{ fields.contact.city.label || 'City' }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.city === null }">{{ memberInfo.city || "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.state_code">
                  <div class="form-group">
                    <label class="form-label">{{ fields.contact.state_code.label || 'State' }}</label>
                    <span v-if="memberInfo && (memberInfo.country === 'USA' || memberInfo.country === 'Canada')">{{ memberInfo.state_code }}</span>
                    <span v-else-if="memberInfo && memberInfo.state">{{ memberInfo.state }}</span>
                    <span class="text-danger text-bold" v-else>N/A</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.country">
                  <div class="form-group">
                    <label class="form-label">{{ fields.contact.country.label || 'Country' }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.country === null }">{{ memberInfo.country || "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.zip">
                  <div class="form-group">
                    <label class="form-label">{{ fields.contact.zip.label || 'Postal Code' }}</label>
                    <span :class="{ 'text-danger text-bold': memberInfo.zip === null }">{{ memberInfo.zip || "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.citizenship">
                  <div class="form-group">
                    <label class="form-label">{{ fields.contact.citizenship.label || 'Citizenship' }}</label>
                    <span>{{ memberInfo.citizenship || "N/A"}}</span>
                  </div>
                </div>
                <template v-if="memberInfo.membership && memberInfo.membership.id !== 5">
                  <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.parent_citizenship">
                    <div class="form-group">
                      <label class="form-label">{{ fields.contact.parent_citizenship.label_text || 'Parent/Guardian Citizenship' }}</label>
                      <span>{{ memberInfo.parent_citizenship || "No"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.athlete_citizenship">
                    <div class="form-group">
                      <label class="form-label">{{ fields.contact.athlete_citizenship.label_text || 'Child/Athlete Citizenship' }}</label>
                      <span>{{ memberInfo.athlete_citizenship || "No"}}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4" v-if="fields.contact && fields.contact.eligible_citizenship">
                    <div class="form-group">
                      <label class="form-label">{{ fields.contact.eligible_citizenship.label_text || 'Eligible Child/AthleteCitizenship' }}</label>
                      <span>{{ memberInfo.eligible_citizenship || "No"}}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center m-3">
        <i class="fa fa-circle-notch fa-2x fa-spin"></i>
      </div>
    </template>
    <b-modal id="edit-personal" size="xl" ok-title="Update" :title="personalInfoTabTitle" @ok="updatePersonalInfo" centered>
      <div class="form-body" :key="updateIndex">
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label class="form-label" for="first_name">First Name <sup class="text-danger">*</sup></label>
              <input type="text" id="first_name" v-model="form.first_name" value="" placeholder="John" class="form-control">
              <span class="text-sm text-danger">{{ errors.first_name }}</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="form-label" for="middle_name">Middle Initial</label>
              <input type="text" id="middle_name" v-model="form.middle_name" value="" placeholder="A" maxlength="3" class="form-control">
            </div>
            <span class="text-sm text-danger">{{ errors.middle_name }}</span>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label class="form-label" for="last_name">Last Name <sup class="text-danger">*</sup></label>
              <input type="text" id="last_name" v-model="form.last_name" value="" placeholder="Doe" class="form-control">
              <span class="text-sm text-danger">{{ errors.last_name }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">{{ fields.personal && fields.personal.birthday ? fields.personal.birthday.label : 'Date of Birth' }} <sup class="text-danger">*</sup></label>
              <div class="input-group birthdate">
                <div class="birthdaypicker">
                  <date-pick v-model="form.dob" v-mask="'##/##/####'" @input="dobChange" :pickTime="false" name="form.dob" :format="'MM/DD/YYYY'" :selectableYearRange="{from: 1900, to: 2021}" :isDateDisabled="isFutureDate" placeholder="MM/DD/YYYY" :parseDate="parseDatePick">
                  </date-pick>
                </div>
                <p class="text-sm text-danger">{{ errors.dob }}</p>
                <p class="text-sm text-danger">{{ errors.age }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">{{ fields.personal && fields.personal.team_membership_id ? fields.personal.team_membership_id.label : 'Team' }}<sup class="text-danger">*</sup></label>
              <Select2 v-model="form.team" :options="teamOptions" name="form.team" :settings="{ placeholder: 'Select a team' }" @change="updateDivisionLeague" />
              <p class="text-sm text-danger">{{ errors.team }}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">Age Division</label>
              <input type="text" readonly class="form-control" v-model="form.age_division" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">Member League</label>
              <input type="text" readonly class="form-control" v-model="form.member_league" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" v-if="fields.personal && fields.personal.agl_player_id">
            <div class="form-group">
              <label for="agl_player_id" class="form-label">
                {{ fields.personal.agl_player_id.label }}<sup class="text-danger" v-if="fields.personal.agl_player_id.required">*</sup>
              </label>
              <input type="text" class="form-control" :placeholder="fields.personal.agl_player_id.placeholder" maxlength="45" id="agl_player_id" v-model="form.agl_player_id" name="form.agl_player_id" value="">
              <span class="text-sm text-danger">{{ errors.agl_player_id }}</span>
            </div>
          </div>
          <div :class="[fields.personal.graduation_year.colClass]" v-if="fields.personal && fields.personal.graduation_year">
            <div class="form-group">
              <label for="graduation_year" class="form-label">
                {{ fields.personal.graduation_year.label }}<sup class="text-danger" v-if="fields.personal.graduation_year.required">*</sup>
              </label>
              <select class="form-select form-control" id="graduation_year" required="" name="form.graduation_year" v-model="form.graduation_year">
                <option :value="null">Select Graduation Year</option>
                <option v-for="year in graduationYearArr" :value="year" :key="year">{{ year }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.graduation_year }}</span>
            </div>
          </div>
          <div :class="[fields.personal.jersey_number.colClass]" v-if="fields.personal && fields.personal.jersey_number">
            <div class="form-group">
              <label for="jersey_number" class="form-label">
                {{ fields.personal.jersey_number.label }}<sup class="text-danger" v-if="fields.personal.jersey_number.required">*</sup>
              </label>
              <input class="form-control" :placeholder="fields.personal.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="form.jersey_number" name="form.jersey_number" value="">
              <span class="text-sm text-danger">{{ errors.jersey_number }}</span>
            </div>
          </div>
          <div :class="[fields.personal.primary_position.colClass]" v-if="fields.personal && fields.personal.primary_position">
            <div class="form-group">
              <label for="primary_position" class="form-label">{{ fields.personal.primary_position.label }} <sup class="text-danger" v-if="fields.personal.primary_position.required">*</sup></label>
              <select class="form-control" id="primary_position" v-model="form.primary_position" name="form.primary_position">
                <option :value="null">Select</option>
                <option v-for="(listOfPrimaryPosition,key) in fields.personal.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.primary_position }}</span>
            </div>
          </div>
          <div :class="[fields.personal.secondary_position.colClass]" v-if="fields.personal && fields.personal.secondary_position">
            <div class="form-group">
              <label for="secondary_position" class="form-label">{{ fields.personal.secondary_position.label }} <sup class="text-danger" v-if="fields.personal.secondary_position.required">*</sup></label>
              <select class="form-control" id="secondary_position" v-model="form.secondary_position" name="form.secondary_position">
                <option :value="null">Select</option>
                <option v-for="(listOfSecondaryPosition,key) in fields.personal.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.secondary_position }}</span>
            </div>
          </div>
          <div class="col-md-3" v-if="fields.personal && fields.personal.throw">
            <div class="form-group">
              <label class="form-label">{{ fields.personal.throw.label }} <sup class="text-danger" v-if="fields.personal.throw.required">*</sup></label>
              <div class="form-group" v-if="fields.personal.throw.choices">
                <template v-for="(choice, key) in fields.personal.throw.choices">
                  <input type="radio" :id="choice.value" v-model="form.throws" name="form.throw" :value="choice.value" :key="'radio_' + key">
                  <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
                </template>
                <p class="text-sm text-danger">{{ errors.throws }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3" v-if="fields.personal && fields.personal.bats">
            <div class="form-group" >
              <label class="form-label">{{ fields.personal.bats.label }} <sup class="text-danger" v-if="fields.personal.bats.required">*</sup></label>
              <div class="form-group" v-if="fields.personal.bats.choices">
                <template v-for="(choice, key) in fields.personal.bats.choices">
                  <input type="radio" :id="choice.value" v-model="form.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                  <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
                </template>
                <p class="text-sm text-danger">{{ errors.bats }}</p>
              </div>
            </div>
          </div>
          <div :class="[fields.personal.college_committed.colClass]" v-if="fields.personal && fields.personal.college_committed">
            <div class="form-group">
              <label class="form-label">{{ fields.personal.college_committed.label }} <sup class="text-danger" v-if="fields.personal.college_committed.required">*</sup></label>
              <div class="form-group" v-if="fields.personal.college_committed.choices">
                <template v-for="(choice, key) in fields.personal.college_committed.choices">
                  <input type="radio" :id="choice.value" v-model="form.college_list" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                  <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
                </template>
              </div>
            </div>
          </div>
          <div :class="[fields.personal.college_committed.yes_fields.college_list.colClass]" v-if="fields.personal && fields.personal.college_committed && fields.personal.college_committed.yes_fields && fields.personal.college_committed.yes_fields.college_list && collegeCommitted == 1">
            <div class="form-group">
              <label class="form-label" v-if="fields.personal.college_committed.yes_fields.college_list.label !=''">{{ fields.personal.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="fields.personal.college_committed.yes_fields.college_list.required">*</sup></label>
              <Select2 v-model="form.college_committed" :options="collegeOptions" name="form.college_list" @select="updateCollegeCommittedInfo($event)" :settings="{ placeholder: 'Click to Select the College'}" />
              <span class="text-sm text-danger">{{ errors.college_committed }}</span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="edit-contact" size="xl" ok-title="Update" :title="contactInfoTabTitle" @ok="updateContactInfo" centered>
      <div class="form-body" :key="updateIndex">
        <div class="row">
          <div class="col-md-4" v-if="fields.contact && fields.contact.firstname">
            <div class="form-group">
              <label class="form-label" for="parent_first_name">Parent First Name<sup class="text-danger">*</sup></label>
              <input type="text" v-model="form.parent_first_name" class="form-control" id="parent_first_name" name="verifyPersonal.parent_first_name" placeholder="" value="">
              <span class="text-danger">{{ errors.parent_first_name }}</span>
            </div>
          </div>
          <div class="col-md-4" v-if="fields.contact && fields.contact.middlename">
            <div class="form-group">
              <label class="form-label" for="parent_middle_name">Parent Middle Name</label>
              <input type="text" v-model="form.parent_middle_name" class="form-control" id="verify-parent_middle_name" name="verifyPersonal.parent_middle_name" placeholder="" value="">
              <span class="text-danger">{{ errors.parent_middle_name }}</span>
            </div>
          </div>
          <div class="col-md-4" v-if="fields.contact && fields.contact.lastname">
            <div class="form-group">
              <label class="form-label" for="parent_last_name">Parent Last Name<sup class="text-danger">*</sup></label>
              <input type="text" v-model="form.parent_last_name" class="form-control" id="verify-parent_last_name" name="verifyPersonal.parent_last_name" placeholder="" value="">
              <span class="text-danger">{{ errors.parent_last_name }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label">{{ fields.contact && fields.contact.homephone ? fields.contact.homephone.label : 'Phone'}} <sup class="text-danger">*</sup></label>
              <vue-tel-input ref="phone" @validate="validateTelInput" v-model="form.phone_1" v-bind="telProps" :validCharactersOnly="true"></vue-tel-input>
              <span class="text-danger">{{ errors.phone_1 }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label">{{ fields.contact && fields.contact.mobilephone ? fields.contact.mobilephone.label : 'Alternative Phone'}}</label>
              <vue-tel-input ref="phone" @validate="validateTelInput" v-model="form.phone_2" v-bind="telProps" :validCharactersOnly="true"></vue-tel-input>
              <span class="text-danger">{{ errors.phone_2 }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label" for="email">Email <sup class="text-danger">*</sup></label>
              <input type="email" id="email" v-model="form.email" value="" placeholder="William.smith@email.com" class="form-control">
              <span class="text-danger">{{ errors.email }}</span>
            </div>
          </div>
          <div class="col-md-6" v-if="fields.contact && fields.contact.address1">
            <div class="form-group">
              <label class="form-label" for="address1">{{ fields.contact.address1.label || 'Mailling Address'}} <sup class="text-danger" v-if="fields.contact.address1.required">*</sup><span v-if="fields.contact.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;"> {{ fields.contact.address1.charLimitText }}</span></label>
              <input type="text" id="address1" v-model="form.address1" :maxlength=fields.contact.address1.charLimitValue value="" placeholder="8080 Holister" class="form-control">
              <span class="text-sm text-danger">{{ errors.address1 }}</span>
            </div>
          </div>
          <div class="col-md-6" v-if="fields.contact && fields.contact.address2">
            <div class="form-group">
              <label class="form-label" for="address2">{{ fields.contact.address2.label || 'Address2'}} <span v-if="fields.contact.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ fields.contact.address2.charLimitText }}</span></label>
              <input type="text" id="address2" v-model="form.address2" :maxlength=fields.contact.address2.charLimitValue value="" placeholder="Apt.400" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" v-if="fields.contact && fields.contact.country">
            <div class="form-group">
              <label for="country" class="form-label">{{ fields.contact.country.label }} <sup class="text-danger" v-if="fields.contact.country.required">*</sup></label>
              <select class="form-control" id="country" @change="removeStateCode" v-model="form.country" name="form.country"  v-if="fields.contact.country.options === undefined">>
                <option :value="null">Select</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country === 'Bahamas'">{{ listOfCountry.country }}</option>
              </select>
              <select class="form-control" @change="removeStateCode" v-model="form.country" name="form.country"  v-else>
                <option :value="null">Select</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in fields.contact.country.options" :key="listOfCountry.country">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.country}}</span>
            </div>
          </div>
          <div class="col-md-4" v-if="fields.contact && fields.contact.city">
            <div class="form-group">
              <label class="form-label" for="city">City <sup class="text-danger" v-if="fields.contact.city.required">*</sup></label>
              <input type="text" id="city" v-model="form.city" value="" placeholder="FL" class="form-control">
              <span class="text-sm text-danger">{{ errors.city }}</span>
            </div>
          </div>
          <div class="col-md-4" v-if="form.country === 'USA' || form.country === 'Canada'">
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.contact.state_code.label }} <sup class="text-danger" v-if="fields.contact.state_code.required">*</sup></label>
              <select class="form-control" id="state_code" v-model="form.state_code" name="form.state_code">
                <option :value="null">Select</option>
                <template v-if="form.country === 'USA'">
                  <option v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                </template>
                <template v-if="form.country === 'Canada'">
                  <option v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                </template>
              </select>
              <span class="text-sm text-danger">{{ errors.state_code}}</span>
            </div>
          </div>
          <div class="col-md-4" v-else-if="fields.contact && fields.contact.other_state">
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.contact.other_state.label }}</label>
              <input type="text" class="form-control" maxlength="45" v-model="form.state" name="form.state">
              <span class="text-sm text-danger">{{ errors.state}}</span>
            </div>
          </div>
          <div class="col-md-4" v-if="fields.contact && fields.contact.zip">
            <div class="form-group">
              <label class="form-label" for="zip">Postal Code <sup class="text-danger" v-if="fields.contact.zip.required">*</sup></label>
              <input type="text" id="zip" v-model="form.zip" value="" :maxlength="getSiteProps('general.zip_code_length')" placeholder="" class="form-control">
              <span class="text-sm text-danger">{{ errors.zip }}</span>
            </div>
          </div>
          <div class="col-md-4" v-if="fields.contact && fields.contact.citizenship">
            <div class="form-group">
              <label class="form-label" for="zip">Citizenship <sup class="text-danger" v-if="fields.contact.citizenship.required">*</sup></label>
              <select class="form-control" id="citizenship" v-model="form.citizenship" name="form.country">
                <option :value="null">Select</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.citizenship }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div :class="[fields.contact.parent_citizenship.colClass]" v-if="fields.contact && fields.contact.parent_citizenship">
            <div class="form-group">
              <label for="parent_citizenship" class="form-label">{{ fields.contact.parent_citizenship.label }} <sup class="text-danger" v-if="fields.contact.parent_citizenship.required">*</sup></label>
              <div class="form-group" v-if="fields.contact.parent_citizenship.choices">
                <template v-for="(choice, key) in fields.contact.parent_citizenship.choices">
                  <input type="radio" :id="choice.value" v-model="form.is_parent_citizenship" name="form.parent_citizenship" :value="choice.value" @click="parentCitizenship = choice.value" :key="'radio_' + key">
                  <label class="parent_citizenship-label-span mr-3" :for="choice.value" :key="'parent_citizenship_label_'+key">{{ choice.label }}</label>
                </template>
                <span class="text-sm text-danger">{{ errors.is_parent_citizenship}}</span>
              </div>
            </div>
          </div>
          <div :class="[fields.contact.parent_citizenship.yes_fields.citizenship.colClass]" v-if="fields.contact && fields.contact.parent_citizenship && fields.contact.parent_citizenship.yes_fields.citizenship && form.is_parent_citizenship == 1">
            <div class="form-group">
              <label for="citizenship" class="form-label" v-if="fields.contact.parent_citizenship.yes_fields.citizenship.label !=''">{{ fields.contact.parent_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.parent_citizenship.yes_fields.citizenship.required">*</sup></label>
              <select class="form-control" id="parent_citizenship" v-model="form.parent_citizenship" name="form.parent_citizenship">
                <option :value="null">Select citizenship</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.parent_citizenship}}</span>
            </div>
          </div>
          <div :class="[fields.contact.athlete_citizenship.colClass]" v-if="fields.contact && fields.contact.athlete_citizenship">
            <div class="form-group">
              <label for="athlete_citizenship" class="form-label">{{ fields.contact.athlete_citizenship.label }} <sup class="text-danger" v-if="fields.contact.athlete_citizenship.required">*</sup></label>
              <div class="form-group" v-if="fields.contact.athlete_citizenship.choices">
                <template v-for="(choice, key) in fields.contact.athlete_citizenship.choices">
                  <input type="radio" :id="choice.value" v-model="form.is_athlete_citizenship" name="form.athlete_citizenship" :value="choice.value" @click="athleteCitizenship = choice.value" :key="'radio_' + key">
                  <label class="athlete_citizenship-label-span mr-3" :for="choice.value" :key="'athlete_citizenship_label_'+key">{{ choice.label }}</label>
                </template>
                <span class="text-sm text-danger">{{ errors.is_athlete_citizenship}}</span>
              </div>
            </div>
          </div>
          <div :class="[fields.contact.athlete_citizenship.yes_fields.citizenship.colClass]" v-if="fields.contact && fields.contact.athlete_citizenship && fields.contact.athlete_citizenship.yes_fields.citizenship && form.is_athlete_citizenship == 1">
            <div class="form-group">
              <label for="citizenship" class="form-label" v-if="fields.contact.athlete_citizenship.yes_fields.citizenship.label !=''">{{ fields.athlete_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.contact.athlete_citizenship.yes_fields.citizenship.required">*</sup></label>
              <select class="form-control" id="athlete_citizenship" v-model="form.athlete_citizenship" name="form.athlete_citizenship">
                <option :value="null">Select citizenship</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.athlete_citizenship}}</span>
            </div>
          </div>
          <div :class="[fields.contact.eligible_citizenship.colClass]" v-if="fields.contact && fields.contact.eligible_citizenship" v-show="form.is_parent_citizenship == 2 || form.is_athlete_citizenship == 2">
            <div class="form-group">
              <label for="eligible_citizenship" class="form-label">{{ fields.contact.eligible_citizenship.label }} <sup class="text-danger" v-if="fields.contact.eligible_citizenship.required">*</sup></label>
              <div class="form-group" v-if="fields.contact.eligible_citizenship.choices">
                <template v-for="(choice, key) in fields.contact.eligible_citizenship.choices">
                  <input type="radio" :id="choice.value" v-model="form.is_eligible_citizenship" name="form.eligible_citizenship" :value="choice.value" @click="eligibleCitizenship = choice.value" :key="'radio_' + key">
                  <label class="eligible_citizenship-label-span mr-3" :for="choice.value" :key="'eligible_citizenship_label_'+key">{{ choice.label }}</label>
                </template>
                <span class="text-sm text-danger">{{ errors.is_eligible_citizenship}}</span>
              </div>
            </div>
          </div>
          <div :class="[fields.contact.eligible_citizenship.yes_fields.citizenship.colClass]" v-if="fields.contact && fields.contact.eligible_citizenship && fields.contact.eligible_citizenship.yes_fields.citizenship && form.is_eligible_citizenship == 1 && (form.is_parent_citizenship == 2 || form.is_athlete_citizenship == 2)">
            <div class="form-group">
              <label for="citizenship" class="form-label" v-if="fields.contact.eligible_citizenship.yes_fields.citizenship.label !=''">{{ fields.contact.eligible_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.contact.eligible_citizenship.yes_fields.citizenship.required">*</sup></label>
              <select class="form-control" id="eligible_citizenship" v-model="form.eligible_citizenship" name="form.eligible_citizenship">
                <option :value="null">Select citizenship</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.eligible_citizenship}}</span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="team-confirmation" size="md" ok-title="Yes, I Confirm" cancel-title="No" title="Team Confirmation" @ok="confirmTeamSelection">
      <p>Are you confirm to proceed with the selected team ?</p>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import DatePick from 'vue-date-pick';
import * as $ from 'jquery';
import moment from 'moment';
import {EventBus} from "../../../event-bus";

const validateName = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName !== '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName !== '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateOptionalTextFields = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = (fieldName !== '' ? fieldName : "This field");

  if (names != null && names.length && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateDob = (dob,membership_id,age,isNotAlliance) => {
  if (dob == null || dob.length == 0) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateSelectOption = (select, fieldState='') => {
  let textState = (fieldState !== '' ? fieldState : "This field");
  if(!Number.isInteger(select)){
    if(select === "" || select == null || !select.length){
      return {valid: false, error: textState + " is required"};
    }
  }
  if (select === "" || select == null) {
    return {valid: false, error: textState + " is required"};
  }
  return { valid: true, error: null };
};
const validateRadio = (radio, fieldName='') => {
  let fieldText= (fieldName !== '' ? fieldName : "This field");
  if (!radio) {
    return {valid: false, error: fieldText+" is required"};
  }
  return {valid: true, error: null};
};
const validateJerseyNumber = number => {
  if(!number){
    return {valid: false,error:"This field is required"};
  }
  if(!number.match(/^[0-9]+$/)){
    return { valid: false, error: "Please enter a valid Jersey Number." };
  }
  return {valid: true, error: null};
};

export default {
  props: ['formData', 'fields'],
  components: { DatePick },
  data(){
    return {
      updateIndex: 1,
      dataLoading: false,
      membership_benefits: null,
      teamOptions: [],
      age: 0,
      memberInfo: {
        id: null,
        membership_id: null,
        membership: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        dob: null,
        affiliated_clubs_dropdown: null,
        affiliated_clubs_names: null,
        team: null,
        age_division: null,
        member_league: null,
        college_committed: null,
        college_committed_text: null,
        phone_1: null,
        phone_2: null,
        email: null,
        address1: null,
        address2: null,
        city: null,
        state_code: null,
        state: null,
        country: null,
        zip: null,
        citizenship: null,
        parent_citizenship: null,
        athlete_citizenship: null,
        eligible_citizenship: null,
        team_age_out: false,
        next_season_start_date: null,
        next_age: null,
        membershipFee: null
      },
      form: {
        is_parent_citizenship: 2,
        is_athlete_citizenship: 2,
        eligible_citizenship: 2
      },
      errors: {},
      collegeCommitted: 0,
      graduationYearArr: [],
      validTelInput:false,
      telProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          maxlength:15,
          name:"telephone",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }
      },
      collegeCommittedText: null,
      personalValid: false,
      contactValid: false
    }
  },
  methods: {
    getRenewalAge(dob){
      let age = moment(this.memberInfo?.next_season_start_date).startOf("year").diff(dob,"year", true);
      return  age > 0 ? Math.ceil((Math.floor(age * 1000) / 1000).toFixed(3)) : 0;
    },
    dobChange(){
      this.age = this.getRenewalAge(this.form.dob);
      this.getEligibleTeams(this.form.dob, true);
      this.form.team = null;
      this.form.age_division = null;
      this.form.member_league = null;
    },
    validateTelInput(obj){
      this.validTelInput = obj.valid;
      if(obj.valid){
        this.errors.phone_1 = '';
        this.errors.phone_2 = '';
      }
    },
    fetchMemberInfo(){
      this.dataLoading = true;
      axios.get(process.env.VUE_APP_URL+'api/member/my_profile', { headers: this.memberHeaders })
      .then(res => {
        if(!res?.data?.data?.membership_expiring) this.$router.push({ name: "memberDashboard" });
        this.dataLoading = false;
        this.setViewData(res.data.data);
        this.setEditForm();
        if(this.memberInfo?.dob) {
          this.age = this.getRenewalAge(this.memberInfo?.dob);
        }
        this.getEligibleTeams(this.memberInfo?.dob);
      })
      .catch(err => {
        console.log(err);
      });
    },
    setViewData(resp){
      for (let prop in resp) {
        if ( Object.prototype.hasOwnProperty.call(this.memberInfo, prop) ) {
          if(!this.formData?.memberInfo?.[prop]) {
            this.memberInfo[prop] = resp[prop];
          } else {
            this.memberInfo[prop] = this.formData?.memberInfo[prop];
          }
        }
      }

      this.memberInfo.team_age_out = this.memberInfo.age_division === resp?.age_division ? resp?.team_age_out : false;
      this.memberInfo.team = this.formData?.memberInfo?.team;

      //Reset team details if age division changed
      if(!this.formData?.memberInfo?.affiliated_clubs_names && this.memberInfo.membership_id !== this.formData?.membership_id){
        this.memberInfo.affiliated_clubs_names = resp?.affiliated_clubs_names;
        this.memberInfo.age_division = resp?.age_division;
      }
      //Reset team details if age division changed

      //Update selected membership
      this.memberInfo.membership_id = this.formData?.membership_id;
      this.memberInfo.membership = this.formData?.membership;
      //Update selected membership

      if(this.memberInfo?.membership?.id !== 5) {
        this.memberInfo.college_committed_text = this.formData?.memberInfo?.college_committed_text || resp?.personal_details?.college_details?.name;
        this.memberInfo.agl_player_id = this.formData?.memberInfo?.agl_player_id || resp.personal_details.agl_player_id;
        this.memberInfo.graduation_year = this.formData?.memberInfo?.graduation_year || resp.personal_details.graduation_year;
        this.memberInfo.jersey_number = this.formData?.memberInfo?.jersey_number || resp.personal_details.jersey_number;
        this.memberInfo.primary_position = this.formData?.memberInfo?.primary_position || resp.personal_details.primary_position;
        this.memberInfo.secondary_position = this.formData?.memberInfo?.secondary_position || resp.personal_details.secondary_position;
        this.memberInfo.throws = this.formData?.memberInfo?.throws || resp.personal_details.throws;
        this.memberInfo.bats = this.formData?.memberInfo?.bats || resp.personal_details.bats;
        this.membership_benefits = this.formData?.memberInfo?.membership_benefits || resp.personal_details.membership_benefits;
        this.memberInfo.college_committed = this.formData?.memberInfo?.college_committed || resp.personal_details.college_details?.id;
        this.memberInfo.parent_first_name = this.formData?.memberInfo?.parent_first_name || resp.contact_details.first_name;
        this.memberInfo.parent_middle_name = this.formData?.memberInfo?.parent_middle_name || resp.contact_details.middle_name;
        this.memberInfo.parent_last_name = this.formData?.memberInfo?.parent_last_name || resp.contact_details.last_name;
      }

      this.$emit('recordVerify', {
        membership_id: this.memberInfo?.membership?.id,
        membership: this.memberInfo?.membership,
        memberInfo: this.memberInfo,
        membershipFee: this.memberInfo?.membership?.price,
      });
      this.$emit('recordNCSA',this.membership_benefits);
    },
    setEditForm(){
      if(this.subdomain === 'alliancefastpitch' && this.memberInfo?.membership?.id !== 5) {
        this.getCollegeCommittedsList();
      }
      let formData = {
        first_name: this.memberInfo?.first_name,
        middle_name: this.memberInfo?.middle_name,
        last_name: this.memberInfo?.last_name,
        dob: this.memberInfo?.dob,
        agl_player_id: this.memberInfo?.agl_player_id,
        graduation_year: this.memberInfo?.graduation_year,
        jersey_number: this.memberInfo?.jersey_number,
        primary_position: this.memberInfo?.primary_position,
        secondary_position: this.memberInfo?.secondary_position,
        team: this.memberInfo?.team,
        age_division: this.memberInfo?.age_division,
        member_league: this.memberInfo?.member_league,
        throws: this.memberInfo?.throws || 2,
        bats: this.memberInfo?.bats || 2,
        college_committed: this.memberInfo?.college_committed || 2,

        parent_first_name: this.memberInfo?.parent_first_name,
        parent_middle_name: this.memberInfo?.parent_middle_name,
        parent_last_name: this.memberInfo?.parent_last_name,
        phone_1: this.memberInfo?.phone_1,
        phone_2: this.memberInfo?.phone_2,
        email: this.memberInfo?.email,
        address1: this.memberInfo?.address1,
        address2: this.memberInfo?.address2,
        country: this.memberInfo?.country,
        city: this.memberInfo?.city,
        state_code: this.memberInfo?.state_code,
        state: this.memberInfo?.state,
        zip: this.memberInfo?.zip,
        citizenship: this.memberInfo?.citizenship,
        is_parent_citizenship: this.memberInfo?.parent_citizenship != null? 1:2,
        parent_citizenship: this.memberInfo?.parent_citizenship,
        is_athlete_citizenship: this.memberInfo?.athlete_citizenship != null?1:2,
        athlete_citizenship: this.memberInfo?.athlete_citizenship,
        is_eligible_citizenship: this.memberInfo?.eligible_citizenship != null?1:2,
        eligible_citizenship: this.memberInfo?.eligible_citizenship,
      };

      if(this.memberInfo?.membership?.id === 5) {
        delete formData.agl_player_id;
        delete formData.graduation_year;
        delete formData.jersey_number;
        delete formData.primary_position;
        delete formData.secondary_position;
        delete formData.throws;
        delete formData.bats;
        delete formData.college_committed;
        delete formData.parent_first_name;
        delete formData.parent_middle_name;
        delete formData.parent_last_name;
        delete formData.is_parent_citizenship;
        delete formData.parent_citizenship;
        delete formData.is_athlete_citizenship;
        delete formData.athlete_citizenship;
        delete formData.is_eligible_citizenship;
        delete formData.eligible_citizenship;
      }

      this.form = formData;

      if(this.memberInfo?.membership?.id !== 5) {
        if ([2, 0, null].indexOf(this.form.college_committed) !== -1) {
          this.collegeCommitted = this.form.college_list = 2;
        } else {
          this.collegeCommitted = this.form.college_list = 1;
        }
      }
    },
    validateVerifyData(){
      this.personalValid = this.validatePersonalInfo();
      this.contactValid = this.validateContactInfo();
      this.$emit('validateVerifyData', { personal: this.personalValid, contact: this.contactValid === 0 });
    },
    updateDivisionLeague(event){
      if(this.form.team === 'FA') {
        this.form.affiliated_clubs_names = "Free Agent";
        this.form.age_division = null;
        this.form.member_league = null;
        this.memberInfo.team_age_out = false;
        this.updateIndex++;
      } else {
        axios.get(this.basePath + 'api/team/' + this.form.team + '/division-league', {headers: this.memberHeaders})
            .then(res => {
              this.form.affiliated_clubs_names = res.data?.data?.name;
              this.form.age_division = res.data?.data?.ageDivision;
              this.form.member_league = res.data?.data?.memberLeague;
              this.memberInfo.team_age_out = false;
              this.updateIndex++;
            })
            .catch(err => {
              console.log(err);
            });
      }
    },
    validatePersonalInfo(){
      this.errors = {};
      this.valid = true;

      const validFirstName = validateName(this.form.first_name);
      this.errors.first_name = validFirstName.error;

      const validLastName = validateLastName(this.form.last_name);
      this.errors.last_name = validLastName.error;

      this.age = this.getRenewalAge(this.form.dob);

      const validDob = validateDob(this.form.dob,this.memberInfo.membership?.id,this.age,false);
      this.errors.dob = validDob.error;

      let ageValid = true;
      if(this.propsMembership.age){
        if(this.propsMembership.age.compare === "above" && this.age <= this.propsMembership.age.value) {
          this.errors.age = this.propsMembership.age.error_msg;
          ageValid = false;
        } else if(this.propsMembership.age.compare === "below" && this.age > this.propsMembership.age.value) {
          this.errors.age = this.propsMembership.age.error_msg;
          ageValid = false;
        } else if(!Object.is(Math.abs(this.age), +this.age)) {
          this.errors.age = "Invalid Date";
          ageValid = false;
        }
      }

      let teamValid = false;
      if(!this.memberInfo?.team_age_out) {
        if (this.form.team) teamValid = true;
        else {
          this.errors.team = "This field is required";
        }
      }

      if(this.memberInfo?.membership?.id !== 5) {
        const validPrimaryPosition = validateSelectOption(this.form.primary_position, "Primary");
        this.errors.primary_position = validPrimaryPosition.error;

        const validThrow = validateRadio(this.form.throws);
        this.errors.throws = validThrow.error;

        const validBats = validateRadio(this.form.bats);
        this.errors.bats = validBats.error;

        const validJerseyNumber = validateJerseyNumber(this.form.jersey_number);
        this.errors.jersey_number = validJerseyNumber.error;

        const validGraduationYear = validateSelectOption(this.form.graduation_year);
        this.errors.graduation_year = validGraduationYear.error;

        let collegeCommitted = true;
        if (this.collegeCommitted === 1 && this.form.college_committed === undefined) {
          this.errors.college_committed = "This field is required";
          collegeCommitted = false;
        }

        if (
            !validFirstName.valid ||
            !validLastName.valid ||
            !validDob.valid ||
            !validGraduationYear.valid ||
            !validJerseyNumber.valid ||
            !validPrimaryPosition.valid ||
            !validThrow.valid ||
            !validBats.valid ||
            !collegeCommitted ||
            !ageValid ||
            !teamValid
        ) {
          this.valid = false;
        }
      } else {
        if (
            !validFirstName.valid ||
            !validLastName.valid ||
            !validDob.valid ||
            !ageValid ||
            !teamValid
        ) {
          this.valid = false;
        }
      }
      return this.valid;
    },
    updatePersonalInfo(bvModalEvt){
      bvModalEvt.preventDefault();
      if(this.validatePersonalInfo() === false) return;
      for (let prop in this.form) {
        if ( Object.prototype.hasOwnProperty.call(this.memberInfo, prop) ) {
          this.memberInfo[prop] = this.form[prop]
        }
      }
      if(this.memberInfo?.membership?.id !== 5) {
        if (this.form.college_committed > 0) {
          let college = this.collegeOptions.find(item => parseInt(item.id) === parseInt(this.form.college_committed));
          this.memberInfo.college_committed_text = college.text;
        } else {
          this.memberInfo.college_committed_text = null;
        }
      }
      ++this.updateIndex;
      this.$nextTick(() => {
        this.validateVerifyData();
        this.$bvModal.hide('edit-personal');
      });
    },
    validatePhone(phone, validTelinput, phoneField= ''){
      let phoneText = (phoneField !== '' ? phoneField : "This field");
      if (!phone) {
        return { valid: false, error: phoneText+' is required .' };
      }
      if(validTelinput === false && phone.length){
        return { valid: false, error: 'Enter the Exact phone number.' };
      }
      return { valid: true, error: null };
    },
    validateContactInfo(){
      this.errors = {};
      if(!this.form.email) this.errors.email = "This field is required";
      if(!this.form.address1) this.errors.address1 = "This field is required";
      if(!this.form.country) this.errors.country = "This field is required";
      if(!this.form.city) this.errors.city = "This field is required";
      if(this.form.country){
        if(['USA', 'Canada'].indexOf(this.form.country) !== -1 && !this.form.state_code){
          this.errors.state_code = "This field is required";
        }
        if(['USA', 'Canada'].indexOf(this.form.country) === -1 && !this.form.state){
          this.errors.state_code = "This field is required";
        }
        if(!this.form.zip){
          this.errors.zip = "Zip/Postal Code is required";
        }
        else if(this.form.country == "USA" && !this.form.zip.match(/^\d{5}(-\d{4})?$/)){
          this.errors.zip = "Zip/Postal Code Should Be 5 Number Values for USA";
        }
      }
      const validPhone1 = this.validatePhone(this.form.phone_1, this.validTelinput);
      if(!validPhone1.valid) this.errors.phone_1 = validPhone1.error;

      if(this.memberInfo?.membership?.id !== 5) {
        if (!this.form.parent_first_name) this.errors.parent_first_name = "This field is required";
        if (!this.form.parent_last_name) this.errors.parent_last_name = "This field is required";

        if (this.form.is_parent_citizenship == 1 && !this.form.parent_citizenship) {
          this.errors.parent_citizenship = "This field is required";
        }
        if (this.form.is_athlete_citizenship == 1 && !this.form.athlete_citizenship) {
          this.errors.athlete_citizenship = "This field is required";
        }
        if (this.form.is_eligible_citizenship == 1 && !this.form.eligible_citizenship) {
          this.errors.eligible_citizenship = "This field is required";
        }
      }
      return Object.keys(this.errors).length;
    },
    updateContactInfo(bvModalEvt){
      bvModalEvt.preventDefault();
      if(this.validateContactInfo()) return false;
      for (let prop in this.form) {
        if ( Object.prototype.hasOwnProperty.call(this.memberInfo, prop) ) {
          this.memberInfo[prop] = this.form[prop]
        }
      }
      ++this.updateIndex;
      this.$nextTick(() => {
        this.validateVerifyData();
        this.$bvModal.hide('edit-contact');
      });
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    removeStateCode(){
      this.form.state = null;
      this.form.state_code = null;
    },
    getEligibleTeams(dob, dobChanged = false){
      dob = !dob ? null : moment(dob, "MM/DD/YYYY").format("YYYY-MM-DD");
      axios.post(this.basePath+'api/renew/'+this.formData.membership_id+'/teams-eligible', { dob: dob }, { headers: this.memberHeaders })
      .then(res => {
        let list = res.data?.data;
        this.teamOptions = [];
        if(list) {
          let divisions = Object.keys(list);
          divisions.forEach(division => {
            this.teamOptions.push(list[division]);
          });
          this.teamOptions = this.teamOptions.flat().sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1);
          this.teamOptions = [{id: "FA", text: 'Free Agent'}, ...this.teamOptions];
        }
        if(dobChanged === false && this.memberInfo?.affiliated_clubs_dropdown && Object.keys(this.memberInfo?.affiliated_clubs_dropdown).length){
          let team_id = parseInt(Object.keys(this.memberInfo?.affiliated_clubs_dropdown).shift());
          if(!this.formData?.memberInfo?.team || this.formData?.memberInfo?.team === team_id) {
            let teamFound = this.teamOptions.filter(item => item.id === team_id);
            if(teamFound.length) {
              this.form.team = team_id;
              this.memberInfo.team = this.form.team;
            } else {
              this.form.team = null;
              this.memberInfo.team = null;
              this.memberInfo.affiliated_clubs_names = "";
              this.memberInfo.affiliated_clubs_dropdown = [];
            }
          }
        }

        if(this.formData?.membership_upgraded && this.memberInfo.team_age_out === false) {
          this.memberInfo.team_age_out = true;
          this.form.team = null;
          this.form.age_division = null;
          this.form.member_league = null;
        }

        this.validateVerifyData();
      })
      .catch(err => {
        console.log(err);
      });
    },
    updateCollegeCommittedInfo({ id, text }){
      console.log({ id, text });
    },
    confirmTeamSelection(){
      this.$emit('validateTeamConfirmation', true);
    }
  },
  mounted() {
    const year = new Date().getFullYear();
    const yrs = year+20;
    const twoDigitYear = yrs.toString().substr(-2);
    const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
    this.graduationYearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);
    this.fetchMemberInfo();

    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if(modalId === 'edit-personal') {
        $('#edit-personal___BV_modal_content_').removeAttr('tabindex');
      }
    });

    this.$emit('validateTeamConfirmation', false);
    EventBus.$on('confirmTeamSelection', () => {
      this.$bvModal.show('team-confirmation');
    });
  },
  computed: {
    personalInfoTabTitle(){
      return this.fields?.personal?.tab_title;
    },
    contactInfoTabTitle(){
      return this.fields?.contact?.tab_title;
    },
    propsMembership(){
      let props_membership = null;
      let memberTypes = this.getSiteProps('membershipTypes');
      if(memberTypes) {
        let currentMemberType = memberTypes.filter(item => item.id === this.memberInfo?.membership?.member_type_id);
        if(currentMemberType.length){
          let memberType = currentMemberType.shift();
          if(memberType?.memberships !== undefined && memberType?.memberships?.length){
            props_membership = memberType.memberships.filter(item => item.id === this.memberInfo?.membership?.id);
            if(props_membership.length) props_membership = props_membership.shift();
          }
        }
      }
      return props_membership;
    }
  }
}
</script>
<style>
.verify-view .form-group label.form-label{
  font-weight: normal !important;
}
</style>