<template>
  <div class="row card-body card" v-if="fields.general.ncsa">
    <div :class="[fields.general.ncsa_logo.colClass]">
      <img v-if="fields.general.ncsa_logo && fields.general.ncsa_logo.element && fields.general.ncsa_logo.element == 'img'" :src="fields.general.ncsa_logo.attributes.src" :class="fields.general.ncsa_logo.renew.class">
    </div>
    <div class="col-md-12">
      <div :class="[fields.general.ncsa_description.colClass]">
        <div class="form-group">
          <p class="form-label" v-html="fields.general.ncsa_description.text"></p>
          <ul v-for="point in fields.general.ncsa_description.points" v-bind:key="point.text">
            <li v-html="point.text"></li>
          </ul>
          <p class="form-label" v-html="fields.general.ncsa_description.text2"></p>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-show="fields.general && fields.general.ncsa && fields.general.ncsa.visible">
      <div :class="[fields.general.ncsa.colClass]">
        <div class="form-group">
          <label for="ncsa" class="form-label ncsa-title"><span v-html="fields.general.ncsa.label"></span><sup class="text-danger" v-if="fields.general.ncsa.required">*</sup></label>
          <div class="row form-group ncsa-label" v-if="fields.general.ncsa.choices">
            <template v-for="(choice, key) in fields.general.ncsa.choices">
              <div class="col-md-1" v-bind:key="choice.value">
                <input type="radio" :id="choice.value" v-model="formData" @change="updateNCSASelection" name="form.membership_benefits" :value="choice.value" :key="'radio_' + key">
                <label class="ncsa-label-span" :for="choice.value" :key="'ncsa_label_'+key">{{ choice.label }}</label>
              </div>
            </template>
            <span class="text-sm text-danger">{{ error }}</span>
            <span class="text-sm d-block mt-2 disclaimer" v-if="fields.general.ncsa.disclaimer"><i class="fas fa-info-circle text-danger"></i> {{fields.general.ncsa.disclaimer}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus';
export default {
  props: ['formData', 'fields'],
  data() {
    return {
      error: ""
    }
  },
  methods: {
    updateNCSASelection(){
      this.$emit("recordNCSA", this.formData);
    }
  },
  mounted() {
    EventBus.$on('validateMembershipBenefits', value => {
      this.error = null;
      if(value) this. error = "This field is required.";
    });
  }
}
</script>