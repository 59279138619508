<template>
  <div class="row payment-view">
    <b-overlay :show="showLoader" no-wrap></b-overlay>
    <div class="col-md-6 mb-view-pr mb-view-pl">
      <div class="card-body card">
        <div class="row">
          <div class="col-md-12">
            <h3>Total Amount</h3>
          </div>
          <div class="col-md-12">
            <ul class="list-group mb-3 payment-details">
              <li class="d-flex justify-content-between lh-sm">
                <div><h6 class="my-0"><strong>Membership fee</strong></h6></div>
                <span class="text-muted"><strong>${{ formData.membershipFee | formatDigit }}</strong></span>
              </li>
              <li class="d-flex justify-content-between lh-sm" v-if="donationAmount">
                <div class="cartitemcard">
                  <span class="remove remove-trash-btn" @click="removeDonation"><i class="fas fa-trash-alt"></i></span>
                  <h6 class="my-0">Donation Amount (optional)</h6>
                </div>
                <span class="text-muted"><strong>${{ donationAmount | formatDigit }} </strong></span>
              </li>
              <hr>
              <li class="d-flex justify-content-between" v-if="subTotal">
                <div class="coupon-inner mb-3">
                  <h6 class="my-0"><strong>Coupon</strong></h6>
                </div>
                <div>
                  <input type="text" class="form-control coupon-text" v-model="form.coupon_code">
                  <button class="btn-apply coupon-btn" @click="applyCoupon">Apply Coupon</button>
                  <span class="text-sm text-danger" v-if="couponApplied.error" v-html="couponApplied.error"></span>
                  <span class="text-sm text-success" v-if="couponApplied.success" v-html="couponApplied.success"></span>
                </div>
              </li>
              <li v-if="couponApplied.status === 1" class="d-flex justify-content-between lh-sm">
                <div>
                  <h6 class="my-0">
                    <strong>
                      <a href="javascript:void(0);" class="text-danger" @click="removeCoupon()"><i class="fas fa-trash-alt"></i></a>
                      Discount - {{ couponApplied.text }}
                    </strong>
                  </h6>
                </div>
                <span class="text-muted"><strong>-${{ couponApplied.amount }}</strong></span>
              </li>
              <hr v-if="couponApplied.status === 1">
              <li class="d-flex justify-content-between lh-sm">
                <div><h6 class="my-0"><strong>Sub Total</strong></h6></div>
                <span class="text-muted"><strong>${{ subTotal | formatDigit }}</strong></span>
              </li>
              <hr>
              <template v-if="subTotal">
                <li class="d-flex justify-content-between lh-sm">
                  <div class="processing-tooltip">
                    <h6 class="my-0">
                      <strong>
                        Technology &amp; processing fee
                          <b-button v-b-tooltip.hover :title="this.getSiteProps('general.processingFeeInfo')">
                            <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
                          </b-button>
                      </strong>
                    </h6>
                  </div>
                  <span class="text-danger"><strong>${{ techFee | formatDigit }}</strong></span>
                </li>
                <hr>
                <li class="d-flex justify-content-between lh-sm">
                  <div class="processing-tooltip">
                    <h6 class="my-0"><strong>Card processing fee</strong></h6>
                  </div>
                  <span class="text-danger"><strong>${{ cardFee | formatDigit }}</strong></span>
                </li>
                <hr>
              </template>
              <li class="d-flex justify-content-between lh-sm">
                <div><h6 class="my-0"><strong>Grand Total</strong></h6></div>
                <span class="text-danger"><strong>${{ grandTotal | formatDigit }}</strong></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-view-pl mb-view-pr">
      <div class="card-body card card-wrapper" v-show="subTotal">
        <div class="row">
          <div class="col-md-12 card-info">
            <h3>Card Information</h3>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
              <input type="text" maxlength="16" placeholder="#### #### #### ####" name="paymentForm.cardNumber" class="form-control" v-model="form.cardNumber" @change="recordPayment">
              <span class="text-sm text-danger" v-if="errors.cardNumber">{{ errors.cardNumber }}</span>
            </div>
          </div>
          <div class="col-md-5">
            <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
            <select id="expiration-month" required="required" name="paymentForm.expiration_month" class="form-control form-select" v-model="form.expiration_month" @change="recordPayment">
              <option value="">Month</option>
              <option v-for="item in expMonths" :key="'month'+item" :value="item">{{ item }}</option>
            </select>
            <span class="text-sm text-danger" v-if="errors.expiration_month">{{ errors.expiration_month }}</span>
          </div>
          <div class="col-md-3 mp-l-r pl-0 pr-0 col-md-offset-1" style="margin-top: 30px;">
            <select id="expiration-year" required="required" name="paymentForm.expiration_year" class="form-select form-control" v-model="form.expiration_year" @change="recordPayment">
              <option value="">Year</option>
              <option v-for="item in expYears" :key="'year'+item" :value="item">{{ item }}</option>
            </select>
            <span class="text-sm text-danger" v-if="errors.expiration_year">{{ errors.expiration_year }}</span>
          </div>
          <div class="col-md-3 classcvv">
            <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
            <input type="password" name="paymentForm.cvv" maxlength="4" class="form-control" style="margin-top: 3px;" v-model="form.cvv" @change="recordPayment">
            <span class="text-sm text-danger" v-if="errors.cvv">{{ errors.cvv }}</span>
          </div>
          <div class="col-md-12 mt-3">
            <div class="form-group">
              <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
              <input type="text" placeholder="John Doe" id="name_on_card" name="paymentForm.name_on_card" class="form-control" v-model="form.name_on_card" @change="recordPayment">
              <span class="text-sm text-danger" v-if="errors.name_on_card">{{ errors.name_on_card }}</span>
            </div>
          </div>
          <div class="col-md-12" v-if="isAutoRenewEnabled">
            <div  class="form-check mt-3">
              <input type="checkbox" id="auto-renew-check" class="form-check-input" value="1" v-model="form.auto_renew" @change="recordPayment">
              <label for="auto-renew-check" class="form-check-label light-font">
                <div v-if="formData.membership.member_type_id !== 4">Membership</div>
                <div v-else-if="formData.membership.member_type_id === 4 && subdomain === 'alliancefastpitch'">Team</div>
                <div v-else>Club membership</div> fees will auto-renew and credit card charged upon expiration
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body card card-wrapper" v-show="subTotal">
        <div class="row">
          <div class="col-md-12"><h3>Billing Address</h3></div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Mailing Address<sup class="text-danger">*</sup></label>
              <input type="text" maxlength="45" name="paymentForm.billing_addressline1" class="form-control" v-model="form.billing_addressline1" @change="recordPayment">
              <span class="text-sm text-danger">{{ errors.billing_addressline1 }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>City<sup class="text-danger">*</sup></label>
              <input type="text" maxlength="45" name="paymentForm.billing_city" class="form-control" v-model="form.billing_city" @change="recordPayment">
              <span class="text-sm text-danger">{{ errors.billing_city }}</span>
            </div>
          </div>
          <div class="col-md-6 pr-0" v-if="form.billing_country === 'USA'">
            <div class="form-group">
              <label>State<sup class="text-danger">*</sup></label>
              <select id="state_code" name="paymentForm.billing_state_code" class="form-control" v-model="form.billing_state_code" @change="recordPayment">
                <option value="">Select</option>
                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.billing_state_code }}</span>
            </div>
          </div>
          <div class="col-md-6" v-else>
            <div class="form-group">
              <label for="state_code" class="form-label">Other State <sup class="text-danger">*</sup></label>
              <input type="text" class="form-control" maxlength="45" v-model="form.billing_state" name="paymentForm.billing_state" @change="recordPayment">
              <span class="text-sm text-danger">{{ errors.billing_state }}</span>
            </div>
          </div>
          <div class="col-md-6 pr-0">
            <div class="form-group">
              <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
              <input type="text"  class="form-control" :maxlength="getSiteProps('general.zip_code_length')" name="paymentForm.billing_zip" v-model="form.billing_zip" @change="recordPayment">
              <span class="text-sm text-danger">{{ errors.billing_zip }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Country<sup class="text-danger">*</sup></label>
              <select class="form-control" id="country" name="paymentForm.billing_country" v-model="form.billing_country" @change="recordPayment">
                <template v-if="subdomain !== 'alliancefastpitch'">
                  <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :value="listOfCountry.country">
                    {{ listOfCountry.country }}
                  </option>
                </template>
                <template v-else>
                  <option v-for="listOfCountry in propFields.country.options" :key="listOfCountry.country" :value="listOfCountry.country">
                    {{ listOfCountry.country }}
                  </option>
                </template>
              </select>
              <span class="text-sm text-danger">{{ errors.billing_country }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body card card-wrapper">
        <div class="row">
          <div class="col-md-12 payment-conf confirmsc">
            <h3>Confirm</h3>
            <p>By submitting this information, you agree to the <a href="/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/terms-and-conditions" target="_blank">Terms &amp; Conditions</a> policy
              <template v-if="isAutoRenewEnabled">,<span class="auto_renew_text_sign" style="display: inline-block;"> including AUTO-RENEWAL</span></template></p>
            <div class="">
              <div class="d-flex">
                <input type="checkbox" id="payment_agree" name="paymentForm.agree" style="display: inline-block;" v-model="form.agree" @change="recordPayment">
                <div class="pay-screen" style="width: 60px;">
                  <input type="text" name="paymentForm.sign" maxlength="2" class="chkboxinput ml-2" v-model="form.sign" @change="recordPayment">
                </div>
                <label for="payment_agree" class="form-check-label ml-2">
                  <span style="display: inline-block;">I agree to the {{ getSiteProps('general.site_name') }} <a href="/terms-and-conditions" target="_blank">Terms &amp; Conditions policy</a></span>
                </label>
              </div>
              <span>Please check the box and add your initials in the space provided.</span>
              <span class="text-sm text-danger" v-if="errors.agree">{{ errors.agree }}</span>
              <span class="text-sm text-danger" v-if="errors.sign">{{ errors.sign }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="paymentError">
          <div class="col-md-12">
            <p class="text-danger"><i class="fa fa-times-circle fa-lg mr-2"></i>{{ paymentError.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from "axios";
import { EventBus } from "@/event-bus";
export default {
  props: ['formData'],
  data(){
    return {
      form: {
        expiration_month: '',
        expiration_year: '',
        auto_renew: 0
      },
      donationAmount: 0.0,
      subTotal: 0.0,
      techFee: 0.0,
      cardFee: 0.0,
      grandTotal: 0.0,
      couponApplied: {
        status: 0,
        coupon_code: '',
        amount: 0.00,
        error: null,
        success: null
      },
      errors: {},
      paymentError: null,
      coachRenew: this.formData.coach_renew
    }
  },
  methods: {
    getRenewalAge(dob){
      return moment(this.formData?.memberInfo?.next_renewal_start_date).diff(moment(dob, "MM/DD/YYYY"), 'years');
    },
    applyCoupon(event){
      this.form.coupon_id = null;
      this.form.discount_amount = null;
      this.couponApplied.success = null;
      this.couponApplied.error = null;
      axios.post(process.env.VUE_APP_URL+'api/renew/member/apply-coupon', {
        price: this.formData.membershipFee,
        coupon_code: this.form.coupon_code,
        coupon_type_id: 2,
        member_type_id: this.formData?.membership?.member_type_id,
        membership_id: this.formData?.membership_id
      },{ headers: this.memberHeaders }
      )
      .then(res => {
        if(res.data.status === 'success'){
          this.form.coupon_id = res.data.data.id;
          this.form.discount_amount = res.data.data.value;
          this.calculateTotals();
          this.couponApplied.status = 1;
          this.couponApplied.amount = res.data.data.value;
          this.couponApplied.text = res.data.data.text+' off membership ('+this.form.coupon_code+')';
          this.couponApplied.success = res.data.message;
        } else {
          this.couponApplied.status = 0;
          this.couponApplied.amount = 0.00;
          this.couponApplied.text = '';
          this.couponApplied.error = res.data.message;
          this.calculateTotals();
        }
      })
      .catch(error => {
        console.log(error);
        this.couponApplied.status = 0;
        this.couponApplied.amount = 0.00;
        this.couponApplied.text = '';
        this.calculateTotals();
      });
    },
    removeCoupon(){
      this.couponApplied = Object.assign(this.couponApplied, {
        status: 0,
        coupon_code: '',
        amount: 0.00,
        text: ''
      });
      this.form.coupon_id = null;
      this.form.coupon_code = null;
      this.form.discount_amount = null;
      this.calculateTotals();
    },
    calculateTotals(){
      this.subTotal = this.formData.membershipFee;

      let age = this.getRenewalAge(this.formData?.memberInfo?.dob);
      if(age <= 10 && this.formData?.memberInfo?.age_division === "10U") {
        this.subTotal = parseFloat(process.env.VUE_APP_AFP_10U_FEE);
        this.form.membershipFee = parseFloat(process.env.VUE_APP_AFP_10U_FEE);
      }

      if(this.form.discount_amount !== null && !isNaN(this.form.discount_amount)){
        this.subTotal -= this.form.discount_amount;
        if(this.subTotal < 0) this.subTotal = 0.0;
      }

      let donation_amount = !isNaN(this.formData.donation_amount) ? this.formData.donation_amount : 0;
      let added_donation_amount = !isNaN(this.formData.added_donation_amount) ? this.formData.added_donation_amount : 0;
      this.donationAmount = donation_amount || added_donation_amount || null;
      this.subTotal += parseFloat(donation_amount) + parseFloat(added_donation_amount);

      this.techFee = this.subTotal > 0 ? (this.subTotal * (3.0/100.0)) + 1.50 : 0.0;
      this.cardFee = this.subTotal > 0 ? (this.subTotal + this.techFee) * (2.9/100.0) + 0.30 : 0.0;
      this.grandTotal = this.subTotal + this.techFee + this.cardFee;

      this.form.total = this.grandTotal;

      this.recordPayment();
    },
    recordPayment(){
      this.$emit('recordPayment', this.form);
    },
    fillBillingAddress(){
      this.form.billing_addressline1 = this.formData.memberInfo.address1;
      this.form.billing_city = this.formData.memberInfo.city;
      this.form.billing_state = this.formData.memberInfo.state;
      this.form.billing_state_code = this.formData.memberInfo.state_code;
      this.form.billing_country = this.formData.memberInfo.country;
      this.form.billing_zip = this.formData.memberInfo.zip;
    },
    validatePayment(){
      this.errors = {};
      if (this.subTotal > 0) {
        if (!this.form.cardNumber) this.errors.cardNumber = "Card Number is required";
        if (!this.form.expiration_month) this.errors.expiration_month = "Month is required";
        if (!this.form.expiration_year) this.errors.expiration_year = "Year is required";
        if (!this.form.cvv) this.errors.cvv = "CVV/CVC is required";
        if (!this.form.name_on_card) this.errors.name_on_card = "Name on Card is required";

        if (!this.form.billing_addressline1) this.errors.billing_addressline1 = "Address is required";
        if (!this.form.billing_city) this.errors.billing_city = "City is required";
        if (!this.form.billing_state_code) this.errors.billing_state_code = "State is required";
        if (!this.form.billing_country) this.errors.billing_country = "Country is required";
        if(!this.form.billing_zip){
          this.errors.billing_zip = "Zip/Postal Code is required";
        }
        else if(this.form.billing_country && this.form.billing_country == "USA" && !this.form.billing_zip.match(/^\d{5}(-\d{4})?$/)){
          this.errors.billing_zip = "Zip/Postal Code Should Be 5 Number Values for USA";
        }
        if (!this.form.agree) this.errors.agree = "Please acknowledge by checking the box";
        if (!this.form.sign) this.errors.sign = "Please enter your Initials to proceed";
      }

      this.$emit('recordPayment', { validatePayment: Object.keys(this.errors).length === 0 });
    },
    removeDonation(){
      EventBus.$emit('removeDonation', true);
      this.calculateTotals();
    }
  },
  mounted() {
    this.calculateTotals();
    this.fillBillingAddress();
    EventBus.$on('validatePayment', obj => {
      this.validatePayment();
    });
    EventBus.$on('paymentError', obj => {
      this.paymentError = obj;
    });
  },
  filters: {
    formatDigit(value){
      return value !== "" ? parseFloat(value).toFixed(2) : value;
    }
  },
  computed: {
    expMonths(){
      let array = [];
      for(let i = 1; i <= 12; i++){
        array.push(i.toString().padStart(2, "0"));
      }
      return array;
    },
    expYears(){
      let array = [];
      let currentYear = parseInt(moment(new Date()).format("YYYY"));
      for(let i = currentYear; i <= (currentYear+11); i++){
        array.push(i);
      }
      return array;
    },
    propFields(){
      return this.getSiteProps('memberRegistration.individual.fields.contact');
    },
    isAutoRenewEnabled(){
      return this.getSiteProps('general.member_auto_renewal_option') &&
            this.formData.membership.membership_period !== 999 &&
            (this.formData.membership.member_type_id !== 4 ||
                this.getSiteProps('general.club_auto_renewal_option'));
    }
  }
}
</script>
<style scoped>
.remove-trash-btn{
  cursor: pointer;
}
</style>