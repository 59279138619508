<template>
  <div class="col-md-12">
    <div class="card-body confirmation">
      <div class="row">
        <div class="col-md-12 pt-5">
          <h3 class="text-center text-success"> Thank you for your membership with {{ getSiteProps('general.site_name') }}</h3>
          <div class="form-group text-center">
            <p> This transaction has been approved. <span v-if="formData.transactionId">Transaction ID: {{ formData.transactionId }}</span></p>
            <p>You will receive an email shortly regarding this transaction along with your receipt and membership information.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['formData']
}
</script>